import React from 'react'
import SubHeader from '@/components/addons/SubHeader'
import Hheader from '@/components/Hheader'
import Paragraph from '@/components/Paragraph'

const TermsAndUse = () => {
  return (
    <>
      <SubHeader title="Traddify Africa Innovation Ltd Terms of Use" />
      <section className=" w-[95%] md:w-[90%] lg:w-[80%] mx-auto mt-7">
        <Hheader text="Welcome to Traddify!" />
        <Paragraph
          text='READ THESE TERMS CAREFULLY BEFORE YOU USE THE SERVICES. BY USING THE SERVICES, YOU AFFIRM THAT
a. YOU HAVE READ, UNDERSTOOD, AND AGREED TO THESE TERMS; ANDb. YOU ARE LEGALLY CAPABLE OF ENTERING INTO A LEGAL AGREEMENT.
These Terms of Service is a legal agreement (this "Agreement") between you (the "user," "you" or "your") and Traddify ("Traddify," "we," "our", or "us"), a platform owned and managed by Traddify Africa Innovation Ltd, a private limited company registered under the laws of Nigeria. As used in this Agreement, “Service” refers to Traddify services, as well as our website, any components, documentations, hardware, internet-based services, programs, software, tools, and any updates (including software maintenance, service information, help content, bug fixes, or maintenance releases) provided to you by Traddify, directly or indirectly.
Traddify may offer the Services by exclusively integrating third-party platforms or websites. These third-party platforms or websites might have their own terms of service and Privacy Notice. Traddify’s Terms of Service incorporate by reference all notices, policies, and other content that appear on our website at https://www.traddify.com/ and third-party websites.'
        />
        <Hheader text="INTERPRETATION" />
        <Paragraph text="The following definitions and rules of interpretation will apply in this Agreement –" />
        <Paragraph text='I. “Agreement", "Terms of Service” – means these terms of service as a whole;' />
        <Paragraph text="II. “Data Protection Legislation” – Nigerian Data Protection Regulation 2019, and all other applicable legislations and regulatory requirements in force from time to time which apply to a party, all relating to the use of Personal Data;" />
        <Paragraph text="III. “Documentation” – the documents, guides and other media made available to you by us online at https://www.traddify.com/ or at any other web address notified by us to you or through mobile application, which set out a description of the Services and any user instructions for the Services;" />
        <Paragraph text="IV. “Effective Date” – the date of your first use of and/or access to the Services;" />
        <Paragraph text="V. “Personal Data” – has the meaning defined in Data Protection Legislation;" />
        <Paragraph text="VI. “Services” – Traddify trading platform and other services (including any associated API) provided or made accessible by us to you online at https://www.traddify.com/ or any other website notified by us to you or through mobile application;" />
        <Paragraph text='1. Traddify Services Traddify provides building tools and services that make cheaper, easier, and faster payments and settlements for everyone. We offer (1) fiat/crypto deposits, (2) swaps, payouts, and (3) international settlements through a wide variety of currencies and payment services accessible in different countries. Using our powerful API and leveraging our user-friendly platform, businesses and organizations can offer these services on their platforms; all referred to as "Services.' />
        <Paragraph text="2. Privacy Notice Whenever you use our Services, you consent to the collection, storage, transfer, and use of your information as described in this Privacy Notice. This includes any information you choose to provide that is deemed sensitive under applicable laws and regulations. The terms on which we process any personal information that we collect from you or that you provide to us are described in our Privacy Notice. The Privacy Notice is available on our website at https://www.traddify.com/ and by using the Services, you agree to the Privacy Notice and consent to the processing of any personal data described in the Privacy Notice and you warrant that all data that you provide to us is accurate." />
        <Paragraph text="3. Amendment of Terms We may amend the Terms from time to time. You should visit the Traddify website regularly to check when the Terms were last updated (as displayed at the top of this document) and to review the current Terms. We will do our best to notify you of any amendments to the Terms that we consider likely to materially affect your rights and obligations. Any such notice will be posted on our Site, or sent by email to the address associated with your Traddify account. The continued use of your Traddify account, after any amendment to these Terms, constitutes your acceptance of the Terms, as modified by such amendment. If you do not accept the Terms, or any amendment to them, you must immediately stop using the Traddify Site and your Traddify account." />
        <Paragraph text="4. Using the Services You are responsible for the software, device, and other hardware, Internet service, and mobile data service (and all associated fees) that you need to access and use the Services. We currently charge transaction fees for the Services and reserve the right to change these fees in the future. If we change our fees, applicable rates will be communicated online via Our website https://www.traddify.com/ or we may elect to update you via any of our communication channels.4.1 Eligibility: By opening an Account with us, you expressly warrant and represent that:" />
      </section>
    </>
  )
}
export default TermsAndUse
