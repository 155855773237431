import React from 'react'
import Body from '@/components/addons/Body'
import Footer from '@/components/addons/Footer'
import '@/assests/scss/admin.scss'
import Header from '@/components/addons/Header'
import { Helmet } from 'react-helmet'

const App = () => {
  return (
    <div id="app" className="font-sans overflow-x-hidden">
      <Helmet>
        <meta name="description" content="Default description" />

        {/*Add other default meta tags */}
      </Helmet>
      <Header />
      <Body />
      <Footer />
    </div>
  )
}

export default App
