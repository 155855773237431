import React, { useEffect, useState } from 'react'
import googleplaystore from '@/assests/img/googleplay.png'
import applestore from '@/assests/img/appstore.png'
import mobile_mock from '@/assests/img/traddify_mobile_mock.png'
import phone_mockup from '@/assests/img/phone_mockup.png'
import globe from '@/assests/img/globe.png'
import visacard from '@/assests/img/visacard.png'
import Group1 from '@/assests/img/Group1.png'
import Group2 from '@/assests/img/Group2.png'
import Group3 from '@/assests/img/Group3.png'
import cards from '@/assests/img/cards.png'
import group4 from '@/assests/img/Group4.png'
import { AnimateContainer } from 'react-animate-container'

const Home = () => {
  const [imageban, setimageban] = useState('')

  useEffect(() => {
    if (window.innerWidth >= 600) {
      setimageban(Group3)
    } else {
      setimageban(group4)
    }
  })

  const redirectToPlayStore = () => {
    return (location.href = 'https://play.google.com/store/apps/details?id=com.traddify.remittance')
  }
  const redirectToAppleStore = () => {
    return (location.href = 'https://apps.apple.com/ng/app/traddify/id6449751921')
  }

  return (
    <>
      <section className="md:flex justify-between  items-center w-[100%] px-[5px] md:px-20 py-10 md:py-0 bg-[url('assests/img/banner.png')] bg-no-repeat bg-cover bg-center h-auto">
        <AnimateContainer.bounceInLeft>
          <aside className="flex flex-col gap-9 mt-[80px] text-center md:text-left">
            <h1 className=" text-[28px] md:text-[40px] lg:text-[70px] capitalize text-white  leading-[1em]">
              global <br className="hidden md:block" /> money transfer <br className="hidden md:block" /> made easy
            </h1>
            <p className="text-[18px] md:text-[23px] md:leading-[42px] md:tracking-wide capitalize text-white">
              Send, Receive, Swap, Spend and <br className="hidden md:block" /> make cross border payments{' '}
              <br className="hidden md:block" /> instantly.
            </p>
            <div className="hidden md:flex justify-between w-[250px] gap-3">
              <img
                onClick={() => redirectToPlayStore()}
                src={googleplaystore}
                alt="google playtore"
                className="object-cover object-center cursor-pointer"
              />
              <img
                onClick={() => redirectToAppleStore()}
                src={applestore}
                className="object-cover object-center cursor-pointer"
                alt="google playtore"
              />
            </div>
          </aside>
        </AnimateContainer.bounceInLeft>
        <aside className="block mt-[80px]">
          <div className="flex md:hidden justify-center w-[250px] mt-7 mx-auto gap-3">
            <img
              src={googleplaystore}
              onClick={() => redirectToPlayStore()}
              alt="google playtore"
              className="max-w-[60%] object-contain object-center cursor-pointer"
            />

            <img
              onClick={() => redirectToAppleStore()}
              src={applestore}
              className="max-w-[60%] object-contain object-center cursor-pointer"
              alt="google playtore"
            />
          </div>
          <AnimateContainer.backInRight>
            <img src={mobile_mock} alt="mobile mockup" className=" max-w-[70%] md:max-w-full mt-9 mx-auto" />
          </AnimateContainer.backInRight>
        </aside>
      </section>
      <AnimateContainer.fadeIn>
        <section className=" w-[90%] h-auto md:h-[354px] md:w-[85%] mx-auto mt-10 rounded-[10px] bg-[url('assests/img/darkbanner.png')] bg-no-repeat bg-cover bg-fixed md:bg-local bg-center text-white">
          <div className="flex flex-col-reverse md:flex-row items-center h-full justify-center  pt-6 pb-0 md:py-0 px-5 md:px-20 gap-12">
            <img
              src={phone_mockup}
              alt="phone mockup"
              className="h-[100%] w-[300px] mx-auto md:mx-0 object-center object-fill"
            />
            <div className=" md:text-right mt-10 md:mt-0">
              <div className="mb-5  text-[17px] md:text-3xl flex items-center gap-2 md:gap-4 justify-center md:justify-end">
                <img src={globe} alt="globe" className="h-[30px] w-[30px] object-contain object-center " />
                <p>Global Transfers</p>
              </div>
              <p className="text-[#B9B9B9] text-[14px] md:text-[24px] text-center md:text-right tracking-widest">
                Send money to family and friends,
                <br className="hidden md:block" /> business partners and suppliers across
                <br className="hidden md:block" /> borders in minutes.
              </p>
            </div>
          </div>
        </section>
      </AnimateContainer.fadeIn>
      <AnimateContainer.backInRight>
        <section className=" w-[90%] md:w-[85%] mx-auto mt-10 md:flex gap-3 items-center justify-between">
          <div className="bg-gray-50 flex-[100%] text-center px-3 py-10 md:p-10 h-full md:h-[400px] flex flex-col gap-9">
            <p className="text-[17px] md:text-2xl">Payroll Management</p>
            <img src={Group1} alt="Group1" className="m-auto h-[150px] w-[150px]" />
            <p className="text-[#715E5E] text-[14px] md:text-[16px] tracking-wide w-[90%] md:w-[80%] mx-auto">
              Focus on growing your business while we manage your payroll and automate payments in multiple
            </p>
          </div>
          <div className="bg-gray-50 mt-7 md:mt-0 flex-[100%] text-center px-3 py-10 md:p-10 h-full md:h-[400px] flex flex-col gap-9">
            <p className="text-[17px] md:text-2xl">Multi-currency Bank Account</p>
            <img src={Group2} alt="Group2" className="m-auto h-[150px] w-[150px]" />
            <p className="text-[#715E5E] text-[14px] md:text-[16px]  tracking-wide w-[90%] md:w-[80%] mx-auto">
              Create multi-currency bank accounts for personal and business use
            </p>
          </div>
        </section>
      </AnimateContainer.backInRight>
      <section className=" w-[90%] h-auto rounded-[10px] md:flex md:items-center md:justify-center md:h-[354px]  md:w-[85%] mx-auto mt-10 md:rounded-[10px] bg-[url('assests/img/darkbanner.png')] bg-no-repeat bg-cover bg-fixed md:bg-local  bg-center text-white">
        <div className="md:flex  items-center justify-center px-10 md:px-20 py-14 md:py-0 gap-12 h-[100%]">
          <div className="text-left">
            <div className="mb-5 text-[17px] md:text-3xl flex items-center gap-2 md:gap-4 justify-center md:justify-start">
              <img
                src={visacard}
                alt="globe"
                className="h-[15px] w-[15px] md:h-[30px] md:w-[30px] object-contain object-center"
              />
              Virtual Card
            </div>
            <p className="text-[#B9B9B9] text-[14px] md:text-[24px]  tracking-widest text-center md:text-left">
              Send money to family and friends,
              <br className="hidden md:block" /> business partners and suppliers across
              <br className="hidden md:block" /> borders in minutes.
            </p>
          </div>
          <img
            src={cards}
            alt="phone mockup"
            className="mt-7 md:mt-0 h-[100%] w-[209px] md:w-[300px] object-center object-contain  mx-auto md:mx-0"
          />
        </div>
      </section>
      <section className=" w-[100%] h-auto md:h-[449px] mx-auto pt-10 md:pt-0 mt-10 bg-black bg-no-repeat bg-cover bg-center text-white">
        <div className=" flex flex-col-reverse md:flex-row md:flex  items-center h-[100%] justify-center px-10 md:px-20 gap-10 md:gap-5">
          <AnimateContainer.bounceInLeft>
            <img
              src={imageban}
              alt="phone mockup"
              className="h-auto w-[400px]  md:h-[100%] md:flex-[100%] md:w-[100%] mx-auto md:mx-0 object-center object-contain"
            />
          </AnimateContainer.bounceInLeft>
          <AnimateContainer.backInRight>
            <div className="md:flex-[100%] text-center md:text-left">
              <div className="text-[18px] md:text-4xl text-[#F700FE]">Purchase and sell tickets</div>
              <p className="text-white text-[18px] md:text-4xl ">
                to your events at a
                <br className="hidden md:block" />
                discounted rate
              </p>
            </div>
          </AnimateContainer.backInRight>
        </div>
      </section>
      <AnimateContainer.fadeIn>
        <section className="w-[85%] mx-auto mt-10 text-center">
          <p className=" text-[18px] md:text-3xl text-[#F700FE] font-bold">So why wait?</p>
          <p className=" text-[18px] md:text-3xl text-black">
            Sign up today and start making <br className="hidden md:block" /> payments with ease!
          </p>
          <div className="flex justify-center  gap-3 mt-7 mx-auto">
            <img
              src={googleplaystore}
              onClick={() => redirectToPlayStore()}
              alt="google playtore"
              className="max-w-[44%] object-contain md:object-cover object-center cursor-pointer"
            />
            <img
              onClick={() => redirectToAppleStore()}
              src={applestore}
              className="max-w-[44%] object-contain md:object-cover  object-center cursor-pointer"
              alt="google playtore"
            />
          </div>
        </section>
      </AnimateContainer.fadeIn>
    </>
  )
}

export default Home
