import React from 'react'
import SubHeader from '@/components/addons/SubHeader'
import Hheader from '@/components/Hheader'
import Paragraph from '@/components/Paragraph'
import virtual from '@/assests/img/virtual.png'
import grow from '@/assests/img/grow.png'

const TermsAndUse = () => {
  return (
    <>
      <SubHeader title="About Us" />
      <section className=" w-[95%] md:w-[90%] lg:w-[80%] mx-auto mt-7">
        <Hheader text="WHO WE ARE" />
        <Paragraph text="Traddify is a global remittance platform that allows individuals and businesses to make cross-border payments seamlessly and Instantly. Traddify is bridging the gap in settlement time for cross border payments within and outside Africa through fiat and CBDCs(Central banks digital currency)." />
        <section className="w-[90%] md:w-[85%] mx-auto mt-10 md:flex gap-3 items-center justify-between">
          <div className="bg-gray-50 flex-[100%] text-center  px-5 py-10 md:py-10 md:px-10 h-[400px] flex flex-col gap-9">
            <p className="text-[18px] md:text-2xl">Grow your business</p>
            <img src={virtual} alt="Group1" className="m-auto h-[150px] w-[150px]" />
            <p className="text-[#715E5E] text-[14px] md:text-[16px] tracking-wide w-[95%] md:w-[80%] mx-auto">
              Grow your business with Traddify & integrate payments with global partners instantly.
            </p>
          </div>
          <div className="bg-[#ffc0cb57] mt-7 md:mt-0 flex-[100%] text-center px-5 py-10 md:py-10 md:px-10 h-full md:h-[400px] flex flex-col gap-9">
            <p className="text-[18px] md:text-2xl">Virtual Cards</p>
            <img src={grow} alt="Group2" className="m-auto h-[150px] w-[150px]" />
            <p className="text-[#715E5E] text-[14px] md:text-[16px]  tracking-wide w-[95%] md:w-[80%] mx-auto">
              Secure virtual Dollar and Naira cards, shop and make payments online with Traddify virtual card.{' '}
            </p>
          </div>
        </section>
      </section>
    </>
  )
}
export default TermsAndUse
