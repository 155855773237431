import * as React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '@/pages/Home'
import Privacy from '@/pages/Privacy'
import TermsAndUse from '@/pages/TermsAndUse'
import AboutUs from '@/pages/AboutUs'
import Business from '@/pages/Business'
import Faq from '@/pages/Faq'

const Body = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="about" element={<AboutUs />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="terms" element={<TermsAndUse />} />
      {/*<Route path="multicurrency" element={<Multicurrency />} />*/}
      {/*<Route path="securedollarcard" element={<SecureDollarCard />} />*/}
      {/*<Route path="stablecoins" element={<StableCoins />} />*/}
      {/*<Route path="paymentlinks" element={<PaymentLinks />} />*/}
      {/*<Route path="flights" element={<Flights />} />*/}
      <Route path="faq" element={<Faq />} />
      <Route path="business" element={<Business />} />
    </Routes>
  )
}

export default Body
