import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from '@/App'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const Root = () => (
  <React.StrictMode>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </React.StrictMode>
)

root.render(<Root />)
