import React from 'react'
import SubHeader from '@/components/addons/SubHeader'
import Hheader from '@/components/Hheader'
import Paragraph from '@/components/Paragraph'

const Privacy = () => {
  return (
    <>
      <SubHeader title="Traddify Africa Innovation Ltd Privacy Notice" />
      <section className=" w-[95%] md:w-[90%] lg:w-[80%] mx-auto mt-7">
        <Hheader text="Welcome to Traddify!" />
        <Paragraph
          text="By using our services, you agree to our Privacy Notice. Our Privacy Notice tells User of Our policies on the collection, use and disclosure of User's information which User provides us when using our Services and informs User about User's privacy rights and the legal protection available to User.
        We use User's Personal data to provide and improve the Services offered by us. By using our Services, User agrees to the collection and use of User's information in the manner described in this Privacy Notice."
        />
        <Hheader text="INTERPRETATION" />
        <Paragraph text="In this Privacy Notice:" />
        <Paragraph text="Account means a unique account created for User to access our Services or parts of our Services." />
        <Paragraph text='Company ("the Company", "We", "Us" or "Our") refers to Traddify Africa Innovation Ltd.' />
        <Paragraph text='"Device" means any device capable of accessing our Services including a computer, a cell phone and a tablet.' />
        <Paragraph text='"Personal Data" refers to any information that relates to an identified or identifiable individual. For the purposes of Nigeria Data Protection Regulation 2019 ("NDPR"), Personal Data means any information relating to User such as Users name, identification number, location, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity.' />
        <Paragraph text='"Services" (also used as "Service") refers to the services provided by the Company as listed on our website and mobile app.' />
        <Paragraph text='“Effective Date” – the date of your first use of and/or access to the Services."Third Party" includes any party other than the Company or User.' />
        <Paragraph text='"User" ("You", "Your" "Merchant") means the individual, company, or other legal entity accessing or using the Services.' />
        <Paragraph text='"User Data” – the data inputted or provided by you, or by us on your behalf to use the Services or facilitate your use of the Services, and includes your Personal Data and bank account information and credentials.' />
        <Hheader text="ABOUT US" />
        <Paragraph text="Traddify is a blockchain powered remittance platform that allows individuals and businesses to make cross-border payments seamlessly and Instantly. Traddify is bridging the gap in settlement time for cross border payments within and outside Africa through stable coins and CBDCs (Central banks digital currency). We encourage the fair use of our platform and our services in the best interest of your business and other users/businesses across multiple countries and industries." />
        <Hheader text="INFORMATION WE COLLECT AND HOW WE COLLECT THE INFORMATION" />

        <Paragraph text="Personal DataWe require you to create an account with us to use our Services. When creating an account, you must provide your full name, or company name where applicable, email address, country, phone number, and a password." />
        <Hheader text="KYC" />

        <Paragraph text="Apart from Your Personal Data, We require You to complete the Know Your Customer (KYC) information such as personal or Business information, personal or Business documents and Bank information for your organization, to activate your account. All KYC information must be submitted and approved before your account can be activated for live transactions." />
      </section>
    </>
  )
}
export default Privacy
