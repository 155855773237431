import * as React from 'react'
import { Link } from 'react-router-dom'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

export const List = ({ to, name }: { to: string; name: string | JSX.Element }): JSX.Element => {
  return (
    <li className="text-[14px] text-[#A6A6A6] my-5 tracking-wide">
      <Link to={to} className="hover:text-white transition-all">
        {name}
      </Link>
    </li>
  )
}
const currentYear = new Date().getFullYear()

const Footer = () => {
  return (
    <footer className="bg-black dark:bg-gray-900 mt-10">
      <div className="mx-auto w-full max-w-screen-xl">
        <div className="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-5">
          <div>
            <p className="text-white">Company</p>
            <ul>
              <List to="/about" name="About Us" />
              <List to="#" name="Blog" />
              <List to="/faq" name="FAQ" />
              <List to="#" name="Careers" />
            </ul>
          </div>
          <div>
            <p className="text-white">Legal</p>
            <ul>
              <List to="/privacy" name="Privacy policy" />
              <List to="/terms" name="Terms of use" />
            </ul>
          </div>
          <div>
            <p className="text-white">Business</p>
            <ul>
              <List to="#" name="Business bank accounts" />
              <List to="#" name="Cross border payments" />
              <List to="#" name="Trade financing" />
              <List to="#" name="Payroll management" />
              <List to="#" name="Invoicing" />
              {/*<List to="/paymentlinks" name="Payment links" />*/}
            </ul>
          </div>
          <div>
            <p className="text-white">Personal</p>
            <ul>
              <List to="#" name="Virtual accounts" />
              {/*<List to="/multicurrency" name="Multi currency conversion" />*/}
              {/*<List to="/securedollarcard" name="Virtual cards" />*/}
              {/*<List to="/paymentlinks" name="Payment links" />*/}
              <List to="#" name="Events / Ticketing" />
              {/*<List to="/flights" name="Flights" />*/}
            </ul>
          </div>
          <div>
            <p className="text-white">Social</p>
            <ul className="flex justify-between">
              <List to="https://www.twitter.com/traddify" name={<TwitterIcon />} />
              <List to="https://www.instagram.com/traddify" name={<InstagramIcon />} />
              <List to="https://www.linkedin.com/company/traddify/" name={<LinkedInIcon />} />
            </ul>
            <p className="mt-7 text-white">Contact us</p>
            <ul>
              <List to="#" name="support@traddify.com" />
            </ul>
          </div>
        </div>
        <hr />
        <div className="px-4 py-6 justify-center dark:bg-gray-700 md:flex md:items-center md:justify-center">
          <span className="text-sm text-gray-500 dark:text-gray-300 sm:text-center">
            © {currentYear} <a href="/">Traddify™</a>. All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
