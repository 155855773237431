import React, { ReactNode } from "react";
import SubHeader from "@/components/addons/SubHeader";
import Hheader from "@/components/Hheader";
import Paragraph from "@/components/Paragraph";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";

export const MessageBox = ({ question, children }: { question: string; children: ReactNode }) => {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<AddIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <p style={{fontSize : '15px', fontWeight : 'bolder'}}>{question}</p>
            </AccordionSummary>
            <AccordionDetails>
                <p style={{fontSize : '14px', color : '#3b3939', lineHeight : '1.9em'}}>{children}</p>
            </AccordionDetails>
        </Accordion>
    );
};

const TermsAndUse = () => {
    return (
        <>
            <SubHeader title="FREQUENTLY ASKED QUESTIONS" />
            <section className=" w-[95%] md:w-[90%] lg:w-[80%] mx-auto mt-7">
                <MessageBox question="What is Traddify?">
                  <p className="font-[15px]">
                        Traddify is a global remittance and financial services platform powered by blockchain
                        technology. With
                        services such as a multi-currency virtual account, a virtual dollar card, and several others,
                        you can be
                        assured that sending money across five countries is as seamless as possible.
                    </p>
                </MessageBox>

                <MessageBox question="What services does Traddify offer?">
                  <p className="font-[15px]">
                        Traddify offers several services such as: <br />
                        <br />
                        <ul className="list-disc flex flex-col gap-3 ">
                            <li>
                                A multi-currency virtual account that supports the dollar, pounds, Keyan shillings,
                                cedis, and naira.
                            </li>
                            <li>A dollar card that can be used for seamless online payments just by simply tapping on
                                your phone.
                            </li>
                            <li>A crypto swap that lets users convert one stablecoin to another or cash in minutes.</li>
                            <li>
                                A payroll management system that saves employers the stress of managing thousands of
                                employees’
                                salaries. Invoice, ticket, and payment links.
                            </li>
                        </ul>
                    </p>
                </MessageBox>

                <MessageBox question="Does Traddify have a mobile app?">
                  <p className="font-[15px]">
                        Yes, Traddify has mobile applications that can be downloaded from both the Google Play Store and
                        Apple
                        Store.
                    </p>
                </MessageBox>

                <MessageBox question="From which region of the world can I access Traddify’s services?">
                  <p className="font-[15px]">
                        Traddify’s services can be accessed from five different countries - Nigeria, Ghana, Kenya, the
                        United
                        Kingdom, and the United States.
                    </p>
                </MessageBox>

                <MessageBox question="How can I fund my Traddify account?">
                  <p className="font-[15px]">
                        Funding your Traddify multicurrency account can be done in two easy steps:
                        <br />

                        <ul className="list-disc flex flex-col gap-3 ">
                            <li>

                                Directly depositing cash from your local bank account into your Traddify account using
                                the account
                                number provided.
                            </li>
                            <li>

                                You can fund your account by directly transferring your stablecoin (e.g. USDT or USDC)
                                into the wallet
                                address provided by Traddify. However, you must ensure to transfer these coins to the
                                right network.
                                Failure to do so may lead to loss of funds.
                            </li>
                        </ul>
                    </p>
                </MessageBox>

                <MessageBox question="How can I withdraw funds from my Traddify account?">
                  <p className="font-[15px]">Traddify gives you the luxury of withdrawing your funds in two ways - by withdrawing in the form
                        of cryptocurrency and transferring to an external wallet or directly withdrawing into your local
                        bank account. Just remember to input your bank details when setting up your Traddify
                        account.</p>
                </MessageBox>

                <MessageBox question="How can I verify my account to meet KYC verification?">
                  <p className="font-[15px]">You can go directly to verify your account once you’ve signed up on Traddify. After verifying and
                        setting up your account, go to Settings then click on KYC verification and then fill in the
                        questions that follow. It’s important to provide the correct information, alongside your
                        government-issued ID, proof of address, and other required documents.</p>
                </MessageBox>

                <MessageBox question="I forgot my password. How can I reset it?">
                  <p className="font-[15px]">Apologies if this has happened to you. At the point of login, click the forgot password and enter
                        the email address you used to create your account. An email containing a link and instructions
                        on how to reset the password will be sent to that email. Read the instructions and click on the
                        link to set up a new password.</p>
                </MessageBox>

                <MessageBox question="What steps can I take to secure my account?">
                  <p className="font-[15px]">

                        Securing your account is a top task that you must do to prevent scammers from laying hands on
                        your funds. Here are some measures to take to secure your account:<br />
                        <br />
                        <ul className="list-disc flex flex-col gap-3 ">
                            <li> Don’t give out your password to anybody, even if it’s an employee of Traddify.</li>
                            <li> Don’t keep your mobile device carelessly.</li>
                            <li> Set up two-factor authentication (2FA).</li>
                        </ul>


                    </p>
                </MessageBox>

                <MessageBox question="How can I set up Two-Factor Authentication?">
                  <p className="font-[15px]">
                        Two-Step authentication is an additional layer of security that guarantees the safety of your
                        Traddify account. It is the second step of verification, in addition to your password, when you
                        sign up or decide to make a withdrawal. To activate the 2FA: <br />
                        <br />
                        <ul className="list-disc flex flex-col gap-3 ">
                            <li>Click on your Traddify account profile.</li>
                            <li>Scroll down to security and enable two-factor authentication.</li>
                            <li>Choose the option of your phone number or email address to serve as channels to receive
                                the verification code.
                            </li>
                            <li>Once the code comes through, enter it into the boxes provided to complete your 2-FA
                                process.
                            </li>
                        </ul>


                    </p>
                </MessageBox>

                <MessageBox question="How can I report suspicious activity?">
                  <p className="font-[15px]">Simply reach out to Traddify’s compliance team to make a report centered on fraud and other
                        suspicious activities.</p>
                </MessageBox>

            </section>
        </>
    );
};
export default TermsAndUse;
