import * as React from 'react'
import { JSX, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import logo from '@/assests/img/logo.png'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AnimateContainer } from 'react-animate-container'
import bankicon from '@/assests/icons/bankicon.svg'
import cryptoicon from '@/assests/icons/cryptoicon.svg'
import debiticon from '@/assests/icons/debiticon.svg'
import flighticon from '@/assests/icons/flighticon.svg'
import utilityicon from '@/assests/icons/utilityicon.svg'
import eventicon from '@/assests/icons/eventicon.svg'
import moneyicon from '@/assests/icons/moneyicon.svg'
import multicurrencyicon from '@/assests/icons/multicurrencyicon.svg'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'

const generic_classname =
  'block py-2 pl-3 pr-4 text-white rounded hover:bg-pink-300 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent'

export const Navlinks = ({ text, to, action }: { text: string; to: string; action: () => void }) => {
  return (
    <li className="uppercase  text-[14px] tracking-wide" onClick={action}>
      <NavLink to={to} className={generic_classname}>
        {text}
      </NavLink>
    </li>
  )
}

export const SubMenu = ({ to, icon, text, action }: { to: string; icon: string; text: string; action: () => void }) => {
  return (
    <MenuItem onClick={action}>
      <NavLink to={to} className="text-[13px] font-sans  py-2 px-1 flex gap-4">
        <img src={icon} className="w-[21px] h-[20px] object-contain object-center" alt="traddify icons" />
        {text}
      </NavLink>
    </MenuItem>
  )
}

const Header = () => {
  const [toggled, setToggled] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement | boolean>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const menuClick = () => {
    setToggled(!toggled)
  }

  const CustomMenus = (): JSX.Element => {
    return (
      <>
        <SubMenu
          action={() => {
            setAnchorEl(false)
            setToggled(false)
          }}
          to={'/'}
          icon={bankicon}
          text={'Virtual bank accounts'}
        />
        <SubMenu
          action={() => {
            setAnchorEl(false)
            setToggled(false)
          }}
          to={'securedollarcard'}
          icon={debiticon}
          text={'Virtual debit cards'}
        />
        <SubMenu
          action={() => {
            setAnchorEl(false)
            setToggled(false)
          }}
          to={'stablecoins'}
          icon={cryptoicon}
          text={'Crypto wallets'}
        />
        <SubMenu
          action={() => {
            setAnchorEl(false)
            setToggled(false)
          }}
          to={'paymentlinks'}
          icon={moneyicon}
          text={'Money Transfer'}
        />
        <SubMenu
          action={() => {
            setAnchorEl(false)
            setToggled(false)
          }}
          to={'multicurrency'}
          icon={multicurrencyicon}
          text={'Multi-currency conversion'}
        />
        <SubMenu
          action={() => {
            setAnchorEl(false)
            setToggled(false)
          }}
          to={'/'}
          icon={eventicon}
          text={'Events & Ticketing'}
        />
        <SubMenu
          action={() => {
            setAnchorEl(false)
            setToggled(false)
          }}
          to={'flights'}
          icon={flighticon}
          text={'Flights'}
        />
        <SubMenu
          action={() => {
            setAnchorEl(false)
            setToggled(false)
          }}
          to={'paymentlinks'}
          icon={utilityicon}
          text={'Utility Bills Payments'}
        />
      </>
    )
  }

  //  useEffect(() => {
  //    document.addEventListener('click', function (e) {
  //
  //      if (!e.target.hasAttribute('data-collapse-toggle') && e?.target?.getAttribute('id') !== "nav-product") {
  //        setToggled(false)
  //      }
  //    })
  //  })

  const location = useLocation()

  return (
    <nav
      className={` ${
        location.pathname === '/' ? 'bg-[#07070726]' : 'bg-black'
      } border-gray-200 dark:bg-gray-900  w-[100%] absolute top-0 z-10`}
    >
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="/" className="flex items-center">
          <img src={logo} className="h-12 mr-3 object-contain object-center" alt="Logo" />
        </a>
        <div>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center  ml-3 outline-none text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={() => menuClick()}
          >
            {!toggled ? (
              <MenuIcon style={{ color: 'white', fontSize: '30px' }} />
            ) : (
              <CloseIcon style={{ color: 'white', fontSize: '30px' }} />
            )}
          </button>
          <AnimateContainer.fadeIn>
            <div
              className={`${toggled ? '' : 'hidden'} w-full md:block md:w-auto absolute right-0  md:relative`}
              id="navbar-default"
            >
              <ul className="bg-black md:bg-transparent font-medium flex flex-col  p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 dark:border-gray-700">
                <Navlinks text={'Personal'} to={'/'} action={() => setToggled(false)} />
                <Navlinks text={'Business'} to="/business" action={() => setToggled(false)} />
                <div id="nav-product" className="bg-black block md:hidden">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                      style={{ backgroundColor: 'black', color: 'white', padding: '0 0.75rem  0 0.75rem' }}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p style={{ fontSize: '15px', fontWeight: 'bolder' }}>Products</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CustomMenus />
                    </AccordionDetails>
                  </Accordion>
                </div>

                {/*<li className="uppercase text-[14px] tracking-wide hidden md:block">*/}
                {/*  <button*/}
                {/*    id="basic-button"*/}
                {/*    aria-controls={open ? 'basic-menu' : undefined}*/}
                {/*    aria-haspopup="true"*/}
                {/*    aria-expanded={open ? 'true' : undefined}*/}
                {/*    onClick={handleClick}*/}
                {/*    className={generic_classname}*/}
                {/*  >*/}
                {/*    PRODUCTS*/}
                {/*    <ExpandMoreIcon />*/}
                {/*  </button>*/}
                {/*  <Menu*/}
                {/*    id="basic-menu"*/}
                {/*    anchorEl={anchorEl}*/}
                {/*    open={open}*/}
                {/*    onClose={handleClose}*/}
                {/*    MenuListProps={{*/}
                {/*      'aria-labelledby': 'basic-button',*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <CustomMenus />*/}
                {/*  </Menu>*/}
                {/*</li>*/}
                <Navlinks text={'help'} to="/faq" action={() => setToggled(false)} />
              </ul>
            </div>
          </AnimateContainer.fadeIn>
        </div>
      </div>
    </nav>
  )
}

export default Header
