import React from 'react'

const SubHeader = ({ title }: { title: string }) => {
  return (
    <section className="bg-[#4401D1] w-full h-[80px] text-white flex justify-center items-center mt-[70px] text-[14px] md:text-[20px]">
      {title}
    </section>
  )
}

export default SubHeader
