import React, { useEffect, useState } from 'react'
import businessDashboard from '@/assests/img/businessDashboard.png'
import banklink from '@/assests/img/banklink.svg'
import paylink from '@/assests/img/paylink.svg'
import invoice from '@/assests/img/invoice.svg'
import Group3 from '@/assests/img/Group3.png'
import group4 from '@/assests/img/Group4.png'
import paymentglobe from '@/assests/img/paymentglobe.png'

import { AnimateContainer } from 'react-animate-container'

const Business = () => {
  const [imageban, setimageban] = useState('')

  useEffect(() => {
    if (window.innerWidth >= 600) {
      setimageban(Group3)
    } else {
      setimageban(group4)
    }
  })

  const redirectToPlayStore = () => {
    return (location.href = 'http://business.traddify.com/')
  }

  return (
    <>
      <section className="md:flex justify-between  items-center w-[100%]  py-10 md:py-0 bg-[url('assests/img/banner.png')] bg-no-repeat bg-cover bg-center h-auto md:h-[100vh]  ">
        <AnimateContainer.bounceInLeft>
          <aside className="flex flex-col gap-9 mt-[80px] text-center pl-[5px]  md:text-left md:pl-20 w-[85%] mx-auto md:w-full">
            <h1 className=" text-[25px] md:text-[40px] lg:text-[50px] capitalize text-white  leading-[1.3em]">
              Revolutionising The <br className="hidden md:block" /> Way You Manage <br className="hidden md:block" />
              Corporate Funds
            </h1>
            <p className="text-[18px] md:text-[23px] md:leading-[42px] md:tracking-wide capitalize text-white tracking-wide">
              Send, Receive Payments From Global <br className="hidden md:block" /> Partners & Suppliers, Manage Payroll
              and <br className="hidden md:block" /> Financial Resources, All From One Portal.
            </p>
            <div className="hidden md:flex justify-between w-[350px] gap-2">
              <button
                onClick={redirectToPlayStore}
                className="bg-transparent border-2 py-3 px-7 rounded-[5px]  text-white text-[14px] mt-7"
              >
                Contact Sales
              </button>
              <button
                onClick={redirectToPlayStore}
                className="bg-[#8D00CA] py-3 px-7 rounded-[5px]  text-white text-[14px] mt-7"
              >
                Get Started
              </button>
            </div>
          </aside>
        </AnimateContainer.bounceInLeft>
        <aside className="block mt-10 md:mt-[80px] relative">
          <div className="flex md:hidden justify-center w-full mt-7 mx-auto gap-3">
            <button
              onClick={redirectToPlayStore}
              className="bg-[#8D00CA] py-3 px-7 rounded-[5px]  text-white text-[14px] mt-7"
            >
              Get Started
            </button>
            <button
              onClick={redirectToPlayStore}
              className="bg-transparent border-2 py-3 px-7 rounded-[5px]  text-white text-[14px] mt-7"
            >
              Contact Sales
            </button>
          </div>
          <AnimateContainer.backInRight>
            <img
              src={businessDashboard}
              alt="mobile mockup"
              className=" max-w-[70%] hidden md:block md:max-w-full mx-auto "
            />
          </AnimateContainer.backInRight>
        </aside>
      </section>
      <section className="w-[100%] mx-auto py-10 text-center bg-[#F8F8F8]">
        <p className=" text-[18px] md:text-3xl font-bold">
          Global <span className="text-[#8D00CA] ">Payroll Management</span>
        </p>
        <p className=" text-[14px] md:text-[16px] text-black mt-5 w-[85%] mx-auto md:w-full">
          Focus on growing your business while we efficiently handle the <br className="hidden md:block" /> intricacies
          of multi-currency payroll management across <br className="hidden md:block" /> international borders.
        </p>
        <div className="flex justify-center  gap-3 mt-7 mx-auto">
          <button
            onClick={redirectToPlayStore}
            className=" py-3 px-7 rounded-[5px]  text-white text-[14px] mt-7 bg-[#8D00CA]"
          >
            Get Started
          </button>
          <button
            onClick={redirectToPlayStore}
            className="bg-transparent border-2 py-3 px-7 rounded-[5px]  text-black text-[14px] mt-7"
          >
            Schedule demo
          </button>
        </div>
      </section>

      <AnimateContainer.fadeIn>
        <section className=" w-[90%] h-auto mx-auto mt-10  ">
          <div className="flex flex-col-reverse  md:flex-row  h-full justify-between items-center  pt-6 pb-0 md:py-0 px-5 md:px-20 ">
            <div className="mt-10 md:mt-0 text-center md:text-left">
              <p className=" text-[18px] md:text-3xl font-bold text-[#8D00CA] ">
                Cross Border <span className="text-black">Payment</span>
              </p>
              <p className=" text-[14px] md:text-[16px] text-black mt-5">
                Send, Receive, Swap, Spend and make cross border <br /> payments instantly.
              </p>
              <div className="flex  gap-3 mt-7 mx-auto justify-center md:justify-normal">
                <button
                  onClick={redirectToPlayStore}
                  className=" py-3 px-7 rounded-[5px]  text-white text-[14px] mt-7 bg-[#8D00CA]"
                >
                  Get Started
                </button>
              </div>
            </div>
            <img src={paymentglobe} alt="mobile mockup" className=" max-w-[40%] md:max-w-[40%] " />
          </div>
        </section>
      </AnimateContainer.fadeIn>
      <AnimateContainer.backInRight>
        <section className=" w-[90%] md:w-[85%] mx-auto mt-10 md:flex gap-10 items-center justify-between">
          <div className="bg-gray-50 flex-[100%] text-center px-3 py-10 md:p-10 h-full md:h-[500px] flex flex-col gap-9">
            <p className="text-[17px] md:text-2xl">Business Bank Accounts</p>
            <img src={banklink} alt="Group1" className="m-auto h-[150px] w-[150px]" />
            <p className="text-[#715E5E] text-[14px] md:text-[16px] tracking-wide w-[90%] md:w-[80%] mx-auto">
              Create virtual bank accounts in multiple currencies to streamline payments and facilitate international
              trade transactions.
            </p>
          </div>
          <div className="bg-gray-50 mt-7 md:mt-0 flex-[100%] text-center px-3 py-10 md:p-10 h-full md:h-[500px] flex flex-col gap-9">
            <p className="text-[17px] md:text-2xl">Invoice & Receipts</p>
            <img src={invoice} alt="Group2" className="m-auto h-[150px] w-[150px]" />
            <p className="text-[#715E5E] text-[14px] md:text-[16px]  tracking-wide w-[90%] md:w-[80%] mx-auto">
              Facilitate payment requests, issue receipts, maintain precise business records, and streamline financial
              management between buyers and sellers.
            </p>
          </div>
          <div className="bg-gray-50 mt-7 md:mt-0 flex-[100%] text-center px-3 py-10 md:p-10 h-full md:h-[500px] flex flex-col gap-9">
            <p className="text-[17px] md:text-2xl">Payment links</p>
            <img src={paylink} alt="Group2" className="m-auto h-[150px] w-[150px]" />
            <p className="text-[#715E5E] text-[14px] md:text-[16px]  tracking-wide w-[90%] md:w-[80%] mx-auto">
              Efficiently and securely receive online payments from customers through convenient payment links, which
              can seamlessly integrate into invoices.
            </p>
          </div>
        </section>
      </AnimateContainer.backInRight>
      <section className=" w-[90%] h-auto rounded-[10px] md:flex md:items-center md:justify-center md:h-[354px]  md:w-[85%] mx-auto mt-10 md:rounded-[10px] bg-[url('assests/img/darkbanner.png')] bg-no-repeat bg-cover bg-fixed md:bg-local  bg-center text-white">
        <div className="md:flex  items-center justify-center px-10 md:px-20 py-14 md:py-0 gap-12 h-[100%]">
          <div className="text-center">
            <p className="text-[#8D00CA] text-[29px]">
              Trade <span className="text-white">Finance</span>
            </p>
            <p className="text-center text-[15px] text-[#B9B9B9] my-5 leading-8 tracking-wide">
              Supercharge your international trade operations with our specialized trade <br /> finance solutions. From
              flexible financing options to risk mitigation strategies,
              <br /> we empower businesses to navigate the complexities of global trade and <br /> seize growth
              opportunities with ease.
            </p>
            <button
              onClick={redirectToPlayStore}
              className=" py-3 px-7 rounded-[5px]  text-white text-[14px] mt-7 bg-[#8D00CA]"
            >
              Speak with an expert
            </button>
          </div>
        </div>
      </section>

      <AnimateContainer.fadeIn>
        <section className="w-[85%] mx-auto mt-10 text-center">
          <p className=" text-[18px] md:text-[14px]  font-bold">
            <a href="#" className="text-[#8D00CA] underline text-17px">
              24/7 Support
            </a>
            <span className="text-black"> | Cutting-edge technology | 100% Secure</span>
          </p>
        </section>
      </AnimateContainer.fadeIn>
    </>
  )
}

export default Business
